import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Post from "../components/post"
import Placeholder from "../components/placeholder"

export const query = graphql`
  query posts {
    posts: allContentfulBlogPost {
      nodes {
        id
        title
        slug
        date
        featuredImage {
          fluid {
            src
          }
        }
        content {
          json
        }
      }
    }
  }
`

const News = ({ data }) => {
  const { posts } = data
  return (
    <Layout>
      <div className="container container--min-h">
        <div className="section">
          <h1 className="title is-1 mb-6">News</h1>
          <div className="columns is-multiline">
            {posts.nodes.length > 0 ? (
              posts.nodes.map(node => <Post post={node} />)
            ) : (
              <Placeholder />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default News
