import React from "react"
import { Link } from "gatsby"

import { formatDate } from "../utils"

const Post = ({ post }) => {
  return (
    <div className="column is-one-third-desktop is-half-tablet is-full-mobile">
      <Link to={`/news/${post.slug}`}>
        <article className="box article">
          <div className="container has-text-centered">
            <h4 className="title is-4">{post.title}</h4>
            <p className="tag is-info is-light">{formatDate(post.date)}</p>
            <figure class="image mt-3">
              <img src={post.featuredImage.fluid.src} alt="post featured" />
            </figure>
          </div>
        </article>
      </Link>
    </div>
  )
}

export default Post
